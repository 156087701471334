const Heading = {
  baseStyle: {
    textTransform: "uppercase",
    color: "brandWhite.700",
    fontFamily: "ApercuLight",
  },
  variants: {
    "block": {
      paddingTop: "4",
      marginBottom: "4",
      fontWeight: "500",
      fontSize: "32px",

    },    
    "Category": {
      fontSize: "12px",
      paddingBottom: "0",
    },
    "productHeading": {
      color: "brandWhite.700",
      fontWeight: "500",
      fontSize: "24px",
      width: '100%',
      lineHeight: "1.125",
      textTransform: "unset",
    },
    "pageHeading": {
      color: "brandWhite.700",
      fontWeight: "500",
      fontSize: ["28px","28px","32px","32px"],
      width: '100%',
      lineHeight: "1.125",
      textTransform: "uppercase",
      marginTop: "5",
      marginBottom: "4"
    },
    "pageSubTitle": {
      color: "brandWhite.700",
      fontWeight: "200",
      fontSize: "28px",
      width: '100%',
      lineHeight: "1.125",
      textTransform: "uppercase",
      marginTop: "5",
      marginBottom: "4"
    },
    "storyHeading": {
      color: "brandWhite.700",
      fontWeight: "700",
      fontSize: "20px",
      width: '100%',
      lineHeight: "1.125",
      textTransform: "unset",
      marginTop: "5",
      marginBottom: "4"
    }
  }
}

export default Heading
