import React, { setGlobal } from 'reactn'
import { graphql } from "gatsby"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import { Global } from '@emotion/react'
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import myTheme from "../styles/theme"
import fonts from "../styles/fonts"
import { LeftDrawer } from '../components/drawer'
import { Header } from '../components/header'
import { Helmet } from 'react-helmet';
// import GlobalHeader from '../components/globalHeader'

const theme = extendTheme(myTheme)
const klarnaEndpoint = process.env.GATSBY_KLARNA_ENDPOINT
const klarnaKey = process.env.GATSBY_KLARNA_KEY
// const gtag = process.env.GATSBY_GTAG_KEY
// var gtagURL = "https://www.googletagmanager.com/gtag/js?id=" + gtag

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

export const SiteContext = ({ children }) => {

  React.useEffect(() => {
    setGlobal({ sidebarOpen: false })
    setGlobal({ client: client })
    setGlobal({ showPayment: true })
  }, [])

  const snipcartURL = process.env.GATSBY_ACTIVE_ENV == 'production' ? "/snipcart.js" : "/snipcartd.js"
  
  return (
    <>
      <Helmet lang="en">
        <script async defer type="text/javascript" src="//l.getsitecontrol.com/pwp62y14.js"></script>
        <link rel="preconnect" href="https://app.snipcart.com" />
        <link rel="preconnect" href="https://cdn.snipcart.com" />
        <link rel="preconnect" href={klarnaEndpoint} />
        <script type="text/javascript" src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
        <script type="text/javascript" src="https://x.klarnacdn.net/kp/lib/v1/api.js" async></script>
        <script type="text/javascript" src="https://x.klarnacdn.net/kp/lib/v1/api.js" async></script>
        <script type="text/javascript" async src={klarnaEndpoint} data-client-id={klarnaKey}></script>

        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <script src={snipcartURL}  type="text/javascript" />

        {/* <script async src={gtagURL} />
        <script dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '` + gtag + `');
        `,
        }} />
        <script dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-K3TP3RX');`,
        }} /> */}

      </Helmet>

      <ChakraProvider theme={theme}>
        <Global styles={fonts} />
        <Header />
        <LeftDrawer />
        {children}
      </ChakraProvider>

    </>
  )
}

export const query = graphql`
  fragment SiteData on DatoCmsSite {
      id
      globalSeo {
        siteName
        titleSuffix
        twitterAccount
        facebookPageUrl
        fallbackSeo {
          title
          description
          twitterCard
          image {
            url
            gatsbyImageData
            smartTags
          }
        }
      }
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  `

