import React, { setGlobal } from 'reactn'
import {  Link } from "gatsby"
import { Box } from '@chakra-ui/react'
import './drawer.css'

export function DrawerMenuItem({ item, key, isSubItem }) {
  const {
    url,
    title,
    isred
  } = item
  return (
      <Box key={key}
        color={isred ? 'red' : 'white'}
        className={isSubItem ? "subMenuBox" : "menuBox"}
      >
        <Link to={url.replace(/\/$/, '')}
          title={`INGMARSON - ${title} content`}
          onClick={() => setGlobal({ sidebarOpen: false })} >
          <div className={isSubItem ? "menu-item sub-menu-item" : "menu-item"}>
            {title}
          </div>
        </Link>
    </Box >

  )
}
