const Text = {
  baseStyle: {
    fontWeight: "500",
    fontSize: '14px',
    marginBottom: '2',
    fontFamily:"ApercuLight",
    lineHeight: "22px",
    whiteSpace: "normal",
    color: 'brandWhite.600',    
    h1: {
      // textTransform: "uppercase",
      fontFamily: "ApercuLight",
      color: "brandWhite.700",
      fontSize: "26px"
    },
    h2: {
      // textTransform: "uppercase",
      fontFamily: "ApercuLight",
      color: "brandWhite.700",
      fontSize: "20px"
    }
  },
  variants: {
    "footer": {
      color: 'brandWhite.50',   
    },
    "stockist": {
      fontSize: "20px"
    },
    "block": {
      fontSize: "20px",
      lineHeight: '30px'
    },
    "pageHeading": {
      fontSize: "18px",
      lineHeight: '30px',
      // marginBottom: '6',
      p: {
        marginTop: '15px',
        fontSize: ["16px","16px","18px","18px"]
      }
    },    
    "story": {
      fontSize: "16px"
    }
  }
}

export default Text
