import React from 'reactn'
import { Box } from '@chakra-ui/react'

export function CartButton({ }) {
  const size = '30px';
  return (
    <Box id="cartButton" height={size} width={size} mt={2} marginRight={'10px'} >
      <button className="snipcart-summary snipcart-checkout">
        <img width={size} height={size} loading='eager' className="basket-icon" src="/images/basket.png" alt="Shopping basket" title="Shopping basket" />
      </button>
    </Box>
  )
}
