import React, { useEffect } from 'reactn'
import { graphql, StaticQuery } from 'gatsby';
import IngmarsonSearch from "../components/ingmarsonSearch"
// import Snipcart from '../components/snipcart'
// import { isReloadRequired, reload } from "./reload"
import Auth from '@aws-amplify/auth'
import { navigate } from "@reach/router"
import { isLoggedIn, signInRequired, setUserID } from "../components/auth/auth"

const Snipcart = React.lazy(() => import ('../components/snipcart' )) ;

const CommonState = ({ snipcartData, pageContext, location, children }) => {

  const {
    rebuildDate,
    apiEndpoint
    // snipcartKey
  } = snipcartData;
  console.log("CHEECK AUTHXXX", location)
  const isSSR = typeof window === "undefined"
  useEffect(() => {
    async function checkAuth() {
      console.log("CHEECK AUTH", location)
      if (!isLoggedIn() && (!signInRequired || (signInRequired && !location.pathname.startsWith('/admin/login')))) {
        const anonymousUser = await Auth.currentCredentials()
        setUserID(anonymousUser.identityId)
        console.log("GOT ANON USER", anonymousUser)
      } else {
        console.log("AUTHLOG NOT GETTING ANON AS SIGN IN IS REQUIRED")
        if (isLoggedIn()) {
          // alert("ALREADY SIGNED IN")
        } else {
          // alert("NO ANON REQUESTED")
        }
      }
    }

    checkAuth()
    
    // if (typeof window !== 'undefined') {
    //   if (isReloadRequired(rebuildDate)) {
    //     console.log("RELOADING")
    //     reload()
    //     window.location.reload()
    //   } else {
    //     console.log("RELOAD NOT NEEDED")
    //   }
    // }
  }, [])

  if (typeof window !== 'undefined' && signInRequired() && !isLoggedIn() && location.pathname !== `/admin/login` && location.pathname !== `/admin/signup`) {
    navigate("/admin/login")
    return null
  }


  return (

    <>
      <IngmarsonSearch pageContext={pageContext} location={location}>
        {children}
      </IngmarsonSearch>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Snipcart url={apiEndpoint}  />
        </React.Suspense>
      )}
    </>

  )
}

export default props => (
  <StaticQuery
    query={graphql`
        query SnipcartInfoQuery {
            site {
              siteMetadata {
                rebuildDate
                apiEndpoint
              }
            }
          }
        `}
    render={({ site }) => <CommonState snipcartData={site.siteMetadata} {...props} />}
  />
)

